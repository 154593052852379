import React from 'react';
import Made2QuoteAppContext from './context/Made2QuoteAppContext';

import {AppContext, AuthService, MessageService, ObaFrame, TwoToast, TwoToolbar} from 'two-app-ui';
import CompaniesService from './services/CompaniesService';
import OrdersService from './services/OrdersService';
import DraftsService from './services/DraftsService';
import ProductsService from './services/ProductsService';
import LocationsService from './services/LocationsService';
import ContactsService from './services/ContactsService';
import {Toast} from 'primereact/toast';
import {SharedLocationsService} from './services/SharedLocationsService';
import {Route, Switch} from 'react-router-dom';
import NewEditDraftForm from './components/Draft/NewEditDraftFrom/NewEditDraftForm';
import DraftListComponent from './components/Drafts/DraftListComponent';
import LeftToolbar from './components/TopFrame/LeftToolbar';
import RightToolbar from './components/TopFrame/RightToolbar';
import M2QUsersService from './services/M2QUsersService';
import config from './config/config';
import {PaCompany} from 'two-core';
import {messages} from './config/messages';
import {Subscription} from 'rxjs';

const authService = new AuthService();
const companiesService = new CompaniesService(authService);
const ordersService = new OrdersService(authService);
const draftsService = new DraftsService(authService);
const productsService = new ProductsService(authService);
const locationsService = new LocationsService(authService);
const usersService = new M2QUsersService(authService);
const contactsService = new ContactsService(authService);
const sharedLocationsService = new SharedLocationsService(authService);
const toastRef = React.createRef<Toast>();
const twoToast = new TwoToast(toastRef);

interface State {
  companies: PaCompany[];
}
class App extends React.Component<{}, State> {
  subscription: Subscription = new Subscription();

  constructor(props: {}) {
    super(props);
    this.state = {
      companies: [],
    };

    this.onCompanyChanged = this.onCompanyChanged.bind(this);
    this.loadCompanies = this.loadCompanies.bind(this);
  }
  async componentDidMount() {
    this.subscription = MessageService.getMessage().subscribe(async message => {
      if (message === messages.topSelectionChanged) {
        this.onCompanyChanged();
      }
    });
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  async loadCompanies() {
    const filters = [
      JSON.stringify({
        field: 'does_quotes',
        value: true,
      }),
    ];
    const orderBys = [JSON.stringify({field: 'name', direction: 'ASC'})];
    companiesService
      ?.getCompanies({
        orderBys: orderBys,
        filters: filters,
      })
      .then(companiesApiListResponse => {
        const companies = (companiesApiListResponse.records as PaCompany[]) ?? [];

        if (!companies.length) {
          localStorage.removeItem(config().keys.current_company_id);
          return;
        }

        const currentCompanyId = localStorage.getItem(config().keys.current_company_id);
        let selectedCompany = companies.find(company => currentCompanyId === company.id);
        if (!currentCompanyId || !selectedCompany) {
          selectedCompany = companies[0];
          localStorage.setItem(config().keys.current_company_id, selectedCompany.id);
          localStorage.setItem(config().keys.current_company, JSON.stringify(selectedCompany));
          localStorage.setItem(config().keys.current_role, selectedCompany.my_role);
        }

        this.setState({
          companies: companies,
        });

        MessageService.sendMessage(messages.topSelectionDataLoaded);
      })
      .catch(error => {
        localStorage.removeItem(config().keys.current_company_id);
        throw new Error('Error ' + error);
      });
  }
  onCompanyChanged() {
    const newCompanyId = localStorage.getItem(config().keys.current_company_id);
    const newCompany = this.state.companies.find(company => company.id === newCompanyId);

    if (newCompany) {
      localStorage.setItem(config().keys.current_role, newCompany.my_role);
    }
  }
  renderTopFrame(companies: PaCompany[]) {
    return <TwoToolbar left={<LeftToolbar />} right={<RightToolbar companies={companies} />} />;
  }
  render() {
    const {companies} = this.state;
    const value = {
      authService: authService,
      usersService: usersService,
      companiesService: companiesService,
      ordersService: ordersService,
      draftsService: draftsService,
      productsService: productsService,
      twoToast: twoToast,
      locationsService: locationsService,
      contactsService: contactsService,
      sharedLocationsService: sharedLocationsService,
    };
    return (
      <Made2QuoteAppContext.Provider value={value}>
        <ObaFrame contextValues={value} topFrame={this.renderTopFrame(companies)} onSuccessfulInit={this.loadCompanies}>
          <Switch>
            <Route path="/draft/:id">
              <NewEditDraftForm />
            </Route>
            <Route path="/draft">
              <NewEditDraftForm />
            </Route>
            <Route path="/drafts">
              <DraftListComponent />
            </Route>
            <Route path="/">
              <DraftListComponent />
            </Route>
            <Route path="/:any">
              <DraftListComponent />
            </Route>
          </Switch>
        </ObaFrame>
        <Toast ref={toastRef} />
      </Made2QuoteAppContext.Provider>
    );
  }
}

export default App;
