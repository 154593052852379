import config from '../config/config';
import {AuthService, UsersService} from 'two-app-ui';
import {PaContact} from 'two-core';

class M2QUsersService extends UsersService {
  protected endpoint: string;
  myContact?: PaContact;

  constructor(aus: AuthService) {
    super(aus);
    this.endpoint = config().endpoints.users;
  }
}

export default M2QUsersService;
